import { MenuItemType, PageType } from "@idcms/store";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NextPage } from "next";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from "next/router";
import * as MenuService from '../app/api/menus/menus.service';
import * as PagesService from '../app/api/pages/pages.service';
import * as MySQLConnector from '../app/api/utils/mysql.connector';
import Layout from "../components/layout/Layout";
import PageHead from "../components/shared/PageHead";

type Page = {
    slug: string;
}

type Props = {
    page: PageType | null;
    menuItems?: MenuItemType[];
}

const Page: NextPage<Props> = ({ menuItems, page }: Props) => {
    const sideMenuItems = menuItems?.filter((mi) => mi.menuName === 'Sidemenu')
    const footerMenuItems = menuItems?.filter((mi) => mi.menuName === 'Footer')
    const footerSecondMenuItems = menuItems?.filter((mi) => mi.menuName === 'Footer second')
    const servicesMenuItems = menuItems?.filter((mi) => mi.menuName === 'Services')
    const informationMenuItems = menuItems?.filter((mi) => mi.menuName === 'Information')
    const router = useRouter()
    return (
        <>
            <PageHead page={page} />
            <Layout servicesMenuItems={servicesMenuItems} sideMenuItems={sideMenuItems} footerMenuItems={footerMenuItems} footerSecondMenuItems={footerSecondMenuItems} informationMenuItems={informationMenuItems}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pl={{ xl: 18, lg: 6, md: 4, sm: 4 }}>
                        <Stack mt={4} sx={{
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 7px', width: '100%',
                            height: '100%'
                        }}>
                            <Stack direction={'row'} alignItems={'center'}
                                sx={{
                                    backgroundColor: '#192357', width: '100%', minHeight: '58px', borderRadius: '0px 0px 0px 20px',
                                    paddingLeft: { xl: 6, lg: 6, md: 6, sm: 6, xs: 4 }
                                }}>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} onClick={() => router.back()}
                                    sx={{ cursor: 'pointer' }}>
                                    <ArrowBackIosNewRoundedIcon sx={{ color: '#fff' }} />

                                    <Typography color={'#fff'} fontWeight={500} fontSize={{ lg: '20px', md: '20px', sm: '18px', xs: '18px' }}
                                        lineHeight={{ lg: '22px', md: '21px', sm: '19px', xs: '19px' }} pt={.2}
                                        sx={{ textTransform: 'uppercase' }}>
                                        {page?.translation?.title}

                                    </Typography>
                                </Stack>
                            </Stack>

                            <Stack pl={{ lg: 6, md: 6, sm: 6, xs: 4 }} pb={6} pr={{ xl: 30, lg: 16, md: 10, sm: 8, xs: 4 }}>
                                <div dangerouslySetInnerHTML={{ __html: page?.translation?.content ?? "" }}
                                    className={'innerHtml'}
                                ></div>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Layout>
        </>
    )
}

export default Page;

export async function getServerSideProps(context: any) {
    const { page: slug } = context.query;

    MySQLConnector.init();
    const pagePromise = PagesService.getPageBySlug(context.locale, slug)
    const menuItemsPromise = MenuService.getMenus(context.locale, ['Sidemenu', 'Footer', 'Footer second', 'Services', 'Information'])

    const [menuItems, page] =
        await Promise.all([menuItemsPromise, pagePromise])

    MySQLConnector.end();

    return {
        props: {
            menuItems: menuItems,
            page: page,
            ...(await serverSideTranslations(context.locale, ['common']))
        }
    }
}
